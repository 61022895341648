import { DatePipe, NgIf, NgFor, NgClass } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectChange } from '@angular/material/select';
import { Config } from '../../../../app.constants';
import {
    ContentDocumentType,
    ContentModel,
    Enums,
    InstanceLinkStatus,
    JsonReply,
    linkCheckModel,
    PopupTableType,
    SearchEngines,
    SoftLegal,
    SoftLegalLinkStatus,
    TableOptions
} from '../../../../instruments/models';
import { AlertService, ContentService, SoftLegalService } from '../../../../instruments/services';
import { TranslateModule } from '@ngx-translate/core';
import { CustomerDocumentsTableComponent } from '../../../create-customer/customer-documents-table/customer-documents-table.component';
import { RmFileUploaderComponent } from '../../../../components/rm-file-uploader/rm-file-uploader.component';
import { MatMenuModule } from '@angular/material/menu';
import { RmTableComponent } from '../../../../components/rm-table/rm-table.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { TextFieldModule } from '@angular/cdk/text-field';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { MatTooltip } from '@angular/material/tooltip';
import { BehaviorSubject, EMPTY, Observable, of, Subject, switchMap, tap } from 'rxjs';


@Component({
    selector: 'app-soft-legal-detail',
    templateUrl: './soft-legal-detail.component.html',
    styles: ['.mat-mdc-menu-item[disabled]{opacity: 0.5} .toBeSavedUnderline::after{ content: ""; position: absolute; box-shadow: 0px 15px 9px 2px var(--accent) !important; bottom: 8px; left: 0; right: 0;}'],
    standalone: true,
    imports: [NgIf, MatTooltip, FormsModule, MatFormFieldModule, MatInputModule, MatDatepickerModule, MatSelectModule, NgFor, MatOptionModule, NgClass, TextFieldModule, MatDialogModule, MatButtonModule, MatCheckboxModule, MatCardModule, RmTableComponent, MatMenuModule, RmFileUploaderComponent, CustomerDocumentsTableComponent, TranslateModule]
})
export class SoftLegalDetailComponent implements OnInit, OnDestroy {
    @Input() data: {link: ContentModel, oldUrl?: string};
    @Input() tableOptions;
    @Output() ok: EventEmitter<any> = new EventEmitter<any>();
    @Output() alert: EventEmitter<string> = new EventEmitter<string>();
    enums = new Enums();
    uploader: boolean = false;
    config = Config;
    uploadHeaders_type0: HttpHeaders;
    uploadHeaders_type1: HttpHeaders;
    totalDocumentsForCustomer: number = 0;
    isResponseChanged: boolean = false;
    isStatusChanged: boolean = false;
    toBeSaved: boolean = false;
    oldSuccessFeeStatus = null;
    subjectforSaveSteps = new BehaviorSubject(null);


    @ViewChild('linkTable') linkTable: any;
    constructor(private httpClient: HttpClient, public contentService: ContentService, private alertService: AlertService, public softLegalService: SoftLegalService) { }

    ngOnInit() {
        if (this.data && this.data.link && this.data.link.contentId) {
            this.uploadHeaders_type0 = this.manageHttpHeadersUpload(ContentDocumentType.General);
            this.uploadHeaders_type1 = this.manageHttpHeadersUpload(ContentDocumentType.CustomerLinkImage);
            this.manageDomain();
            this.oldSuccessFeeStatus = this.data.link.softLegal.successFee
        }
    }

    ngOnDestroy(): void {
        this.subjectforSaveSteps.unsubscribe();
    }

    private manageHttpHeadersUpload(type: number): HttpHeaders {
        let headers = new HttpHeaders();
        headers = headers.append('ContactId', this.data.link.contactId.toString());
        headers = headers.append('ContentId', this.data.link.contentId.toString());
        headers = headers.append('Type', type.toString())
        return headers;
    }

    private manageDomain() {
        const domains: number[] = this.data.link.softLegal.blockedDomainsRequired;

        if (domains.includes(SearchEngines.Google))
            this.data.link.softLegal.isGoogleBlocked = true;
        if (domains.includes(SearchEngines.Yahoo))
            this.data.link.softLegal.isYahooBlocked = true;
        if (domains.includes(SearchEngines.Bing))
            this.data.link.softLegal.isBingBlocked = true;
    }

    public onActionChange(event: MatCheckboxChange, date: string, response: string, checkbox: string = null) {
        if (event.checked) {
            var pipe = new DatePipe('en-US');
            const now = Date.now();
            const myFormattedDate = pipe.transform(now, 'yyyy-MM-dd');
            this.data.link.softLegal[date] = myFormattedDate;
            if (checkbox) {
                this.data.link.softLegal[checkbox] = true;
            }
        }
        else {
            this.data.link.softLegal[date] = null;
        }
    }

    public onActionResponseChange(event: MatSelectChange, date: string, response: string, checkbox: string) {
        if (event.value != null) {
            this.isResponseChanged = true;
            this.toBeSaved = true;
            const check = new MatCheckboxChange();
            check.checked = true;
            this.onActionChange(check, date, response, checkbox);
        }
    }

    public checkUrl() {
        let blockedDomainsRequired = [0];
        if (this.data.link.softLegal.isGoogleBlocked) {
            blockedDomainsRequired.push(SearchEngines.Google);
        }
        if (this.data.link.softLegal.isYahooBlocked) {
            blockedDomainsRequired.push(SearchEngines.Yahoo);
        }
        if (this.data.link.softLegal.isBingBlocked) {
            blockedDomainsRequired.push(SearchEngines.Bing);
        }
        if (blockedDomainsRequired.length > 1) {
            blockedDomainsRequired = blockedDomainsRequired.splice(1);
        }
        this.data.link.softLegal.blockedDomainsRequired = blockedDomainsRequired;



        this.onSave()
    }


    private onSave(){
        this.subjectforSaveSteps.pipe(
            switchMap(() => this.checkLinkIsSharedAlert$()),
            switchMap((value) => value === 'ok' ? this.checkMessageAlert$() : EMPTY),
            switchMap((value) => value === 'ok' ? this.checkSuccessFeeAlert$() : EMPTY),
            switchMap((value) => value === 'ok' ? this.checkForStatusChangedAlert$() : EMPTY),
        )
        .subscribe({
            next: (value) => {
                if(value === 'ok')
                    this.__save()
            }
        })
    }

    /*
    * STEP 1 - Avviso se il link su cui stai lavorando è condiviso
    */
    private checkLinkIsSharedAlert$(): Observable<string> {
        let isLinkShared = null;
        if (this.data.link.contentWidget)
            isLinkShared = this.data.link.contentWidget.othersSoftLegalCustomers != null;

        if (isLinkShared) {
            return this.alertService.prompt(this.softLegalService.alertMsg.shared)
        }
        else{
            return of("ok")
        }
    }

    /*
    * STEP 2 - Avviso se è stato modificato il result e non lo status generico
    */
    private checkMessageAlert$(): Observable<string> {
        //casistica 1 - modificate azioni e status link non modificato
        if (this.isResponseChanged && !this.isStatusChanged) {
            return this.alertService.prompt(this.softLegalService.alertMsg.statusNotChange)
        }
        else {
            return of("ok")
        }
    }


    /*
    * STEP 3 - Avviso se si sta mettendo il link con successFee
    */
    private checkSuccessFeeAlert$(): Observable<string> {
        const action = this.data.link.softLegal as SoftLegal;
        const confirmForSuccessFeeNeed = this.softLegalService.checkSuccessFeeAlertIsNeed(action, this.oldSuccessFeeStatus)
        if(confirmForSuccessFeeNeed)
        {
            return this.alertService.prompt(this.softLegalService.alertMsg.successFee)
        }
        else{
            return of("ok")
        }
    }

    /*
    * STEP 4 - Bloccante - se non c'è un azione uguale allo status
    */
    private checkForStatusChangedAlert$(): Observable<string> {
        //controlliamo che lo stato generico appena modificato sia coerente con le action response
        const action = this.data.link.softLegal as SoftLegal;
        const status = action.status;
        let save = this.softLegalService.CheckForStatusChanged(status, action);
        if (!save) {
            return this.alertService.error(this.softLegalService.alertMsg.actionNotInsert).pipe(
                switchMap((v) => of("return"))
            )
        }
        else{
            return of("ok")
        }  
    }



    /*
    * Dopo i controlli dei vari step posso salvate
    */
    private __save(){
        if (this.data.oldUrl == this.data.link.url) {
            this.httpClient.post<JsonReply<any>>('/api/Content/Update', this.data.link).subscribe(res => {
                this.alert.emit('savedSameUrl')
            });
        } else {
            let params = new linkCheckModel(this.data.link.contactId, this.data.link.contentId, this.data.link.url);
            if (!this.data.link.contentId) {
                this.ok.emit();
            } else {
                this.contentService.UseCheck(params).then(resp => {
                    if (!resp.data || (!resp.data.newUrlIsOtherCustomerContent && !resp.data.newUrlIsCustomerContent)) {
                        this.ok.emit();
                    } else if (resp.data.newUrlIsOtherCustomerContent) {
                        this.alert.emit('newUrlIsOtherCustomerContent')
                    } else if (resp.data.newUrlIsCustomerContent) {
                        this.alert.emit('newUrlIsCustomerContent')
                    }
                });
            }
        }
    }

    public selectEvent(): void {
        this.alertService.linkEvent(this.data.link.contactId, this.data.link.contentId, PopupTableType.LinkEventToLink).subscribe(() => {
            this.linkTable.fetchData();
        });
    }

    public onUpload() {
        this.uploader = true
    }

    //EMITTER
    public fileUploadFinished(event: any, closeAfter: boolean = false) {
        this.uploader = false

        if (closeAfter) {
            this.ok.emit();
        }
    }
}
