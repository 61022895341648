// Core
import { Location } from '@angular/common';
import { EventEmitter, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as moment from 'moment';

// Instruments
import { DomainCategory, DomainUser, Guid, JsonReply, MatrixValues, SoftLegalLinkStatus, TableAction, UserGroup } from '../models';

// Services
import { HttpClient, HttpEventType, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { DomainUserService } from './domainUser.service';
import { ValueMatrixService } from './valueMatrix.service';

@Injectable({
    providedIn: 'root'
})
export class SharedService {

    // DEV ONLY
    LastSiteUpdate: Date = new Date();

    navbarTitle: EventEmitter<any> = new EventEmitter();
    templateValueMatrixLoaded: EventEmitter<any> = new EventEmitter();
    userLoaded: EventEmitter<any> = new EventEmitter<any>();

    availableUsers: DomainUser[];
    availableDomainCategorys: DomainCategory[];
    availableUsersWithoutNA: DomainUser[];

    availableWriterUsers: DomainUser[];
    availableLegalUsers: DomainUser[];
    availableAccountUsers: DomainUser[]; 
    availableSales: DomainUser[];

    loggedUser: DomainUser = DomainUser.Default;
    loggedUserID: string = Guid.Empty;
    viewedCustomerName: string;

    previousUrl: string;
    currentUrl: string;
    accordionState: { [key: string]: boolean } = {
        findContactsFilters: false,
        contactDetails: false,
        commercialDetails: false,
        contactRecalls: false,
        incomingRequest: false,
        factChecking: false,
        contactDocuments: false,
        requestedServices: false,
        workingTeam: false,
        instance: false,
        softLegal: false,
        customerEvents: false,
        events: false,
        assets: false,
        wip: false,
        close: false
    };

    accordionVisible: [
        { visible: boolean, name: 'goal' },
        { visible: boolean, name: 'workingTeam' },
        { visible: boolean, name: 'customerEvents' },
        { visible: boolean, name: 'wip' },
        { visible: boolean, name: 'inheritedAssets' },
        { visible: boolean, name: 'assets' },
        { visible: boolean, name: 'myReport' },
        { visible: boolean, name: 'serp' },
        { visible: boolean, name: 'softLegal' },
        { visible: boolean, name: 'close' }
    ];

    accordionContactVisible: [
        { visible: boolean, name: "contactDetails" },
        { visible: boolean, name: "contactRecalls" },
        { visible: boolean, name: "factChecking" },
        { visible: boolean, name: "contactDocuments" },
        { visible: boolean, name: "relatedContacts" }
    ];

    matrixCategories: MatrixValues;
    macroCategories: any;
    currentLang: string;

    constructor(private httpClient: HttpClient,
        private _location: Location,
        private router: Router,
        private authService: AuthService,
        private domainUserService: DomainUserService,
        private valueMatrixService: ValueMatrixService) {

        this.router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.previousUrl = this.currentUrl;
                this.currentUrl = val.url;

                this.accordionVisible = [
                    { visible: false, name: 'goal' },
                    { visible: false, name: 'workingTeam' },
                    { visible: false, name: 'customerEvents' },
                    { visible: false, name: 'wip' },
                    { visible: false, name: 'inheritedAssets' },
                    { visible: false, name: 'assets' },
                    { visible: false, name: 'myReport' },
                    { visible: false, name: 'serp' },
                    { visible: false, name: 'softLegal' },
                    { visible: false, name: 'close' }
                ];

                this.accordionContactVisible = [
                    { visible: false, name: "contactDetails" },
                    { visible: false, name: "contactRecalls" },
                    { visible: false, name: "factChecking" },
                    { visible: false, name: "contactDocuments" },
                    { visible: false, name: "relatedContacts" }
                ];

                if (val.url === '/findContacts') {
                    for (const key in this.accordionState) {
                        this.accordionState[key] = false;
                    }
                    this.setAccordionState();
                } else {
                    if (localStorage.getItem('accordionState') === null) {
                        this.setAccordionState();
                    } else {
                        this.getAccordionState();
                    }
                }
            }
        });

        this.httpClient.get<JsonReply<any>>('api/Debug/SiteInfo').toPromise().then(resp => {
            if (resp.ok) {
                this.LastSiteUpdate = resp.data.lastSiteUpdate;
            }
        });
    }


    public downloadFile(path: string, exportName: string, headers?: { [key: string]: string }): Promise<boolean> {
        // this.loading = true;

        const defer = new Promise<boolean>((resolve, reject) => {

            let header = new HttpHeaders();

            if (headers) {
                for (const key in headers) {
                    header = header.append(key, headers[key]);
                }
            }

            const data = {
                filename: exportName,
                file: null
            };

            this.httpClient.get(path, { observe: 'events', responseType: 'blob', headers: header }).subscribe(response => {
                if (response.type === HttpEventType.Response) {
                    const responceEnd = response as HttpResponse<Blob>;
                    const contentHeader = responceEnd.headers.get('Content-Disposition');
                    const headerValues = contentHeader.split(';');
                    const filenameValue = headerValues[1].split('=')[1];
                    data.filename = filenameValue.replace(/\"/g, '');

                    this.downloadBlobFile(responceEnd.body, data.filename);
                    // this.loading = false;
                    resolve(true);
                }
            }, (err) => {
                // this.loading = false;
                reject(err);
            });
        });

        return defer;
    }

    // Auth
    public getLoggedUser(): Promise<DomainUser> {
        const q = this.authService.getAccount().then(resp => {
            if (!resp.data) {
                this.authService.logOut();
                this.router.navigate(['/login']);
            }

            this.loggedUser = resp.data;
            this.loggedUserID = resp.data.domainUserId;
            this.getUsers();

            this.userLoaded.emit();
            return resp.data;
        });

        return q;
    }

    private getUsers(): void {
        this.domainUserService.getAvailableUsers().then(resp => {
            this.availableUsers = resp.data;
            this.availableUsersWithoutNA = resp.data.filter(user => user.domainUserId !== Guid.Empty);
        });

        this.domainUserService.getAvailableWriterUsers().then(resp => {
            this.availableWriterUsers = resp.data;
        });

        this.domainUserService.getAvailableLegalUsers().then(resp => {
            this.availableLegalUsers = resp.data;
        });

        this.domainUserService.getAvailableAccounts().then(resp => {
            this.availableAccountUsers = resp.data;
        });

        this.domainUserService.getAvailableSales().then(resp => {
            this.availableSales = resp.data;
        });
    }

    public getValueMatrix(customerId: number): void {
        this.valueMatrixService.getCategoriesList(customerId).then(resp => {
            if (resp.ok) {
                this.matrixCategories = resp.data;
                this.templateValueMatrixLoaded.emit();
            }
        });
    }

    // DATES
    public fromNow(date: Date) {
        return moment(date).fromNow();
    }

    public betweenDates(sooner: Date, later: Date): string {
        return this.betweenDatesSuffix(sooner, later, false);
    }

    public betweenDatesSuffix(sooner: Date, later: Date, withSuffix: boolean): string {
        if (sooner === later) {
            return '';
        }

        let result = moment(later).from(moment(sooner), !withSuffix);

        return result;
    }

    // ACCORDION EXPANSION STATE
    public setAccordionState(): void {
        localStorage.setItem('accordionState', JSON.stringify(this.accordionState));
    }

    public getAccordionState(): void {
        this.accordionState = JSON.parse(localStorage.getItem('accordionState'));
    }

    public panelOpened(propName: string): void {
        this.accordionState[propName] = true;
        this.setAccordionState();
    }

    public panelClosed(propName: string): void {
        this.accordionState[propName] = false;
        this.setAccordionState();
    }

    // navbar
    public setNavbarTitle(title: string, subtitle: string = null, icon: boolean = false): void {
        this.navbarTitle.emit({ title, subtitle, icon });
    }

    // method for copying links from tables on click
    public copyLink(url: string) {
        const textArea = document.createElement('textarea');

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = '0px';
        textArea.style.left = '0px';

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = '0px';

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = url;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            console.log('Copying text command was ' + msg);
        } catch (err) {
            console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);
    }

    // back arrow button event
    public navigateBack(): void {
        this._location.back();
    }

    // create range to iterate with *ngIf a certain amount of times
    public createRange(firstN: number, lastN: number): any[] {
        const arr = [];

        for (let i = firstN; i <= lastN; i++) {
            arr.push(i);
        }

        return arr;
    }

    // disallow entering negative values in mat input of type 'number'
    public disallowNegative(e) {
        if (!((e.keyCode > 95 && e.keyCode < 106)
            || (e.keyCode > 47 && e.keyCode < 58)
            || e.keyCode === 8)) {
            return false;
        }
    }

    public downloadBlobFile(data: any, name: string) {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.setAttribute('href', url);
        link.setAttribute('download', name);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public _preventDefault(event: Event): void {
        event.preventDefault();
    }

    public deepClone(obj: Object): Object {
        return JSON.parse(JSON.stringify(obj));
    }

    public isCurrentUserInSomeGroups(groups: UserGroup[]): boolean {
        return groups.some(group => this.isCurrentUserInGroup(group));
    }

    public isCurrentUserInGroup(group: UserGroup): boolean {
        return this.isUserInRole(this.loggedUser.userGroup, group);
    }

    public allowActionsForUser(actions: TableAction[]): void {
        if (!actions || !this.loggedUser) {
            return;
        }

        const currUserGroup = this.loggedUser.userGroup;

        actions.forEach(action => {
            if (currUserGroup === UserGroup.Admin || action.allowedFor.includes(UserGroup.Any)) {
                action.allowed = true;
            }
            else
                action.allowed = action.allowedFor.some(role => this.isUserInRole(currUserGroup, role));
        });
    }

    public roleNumberToString(value) { // checks if the int we pass as value is in any UserGroup, the answer can be true for multiple groups
        var User = !this.isUserInRole(value, UserGroup.Users) ? "" : "User"
        var Sales = !this.isUserInRole(value, UserGroup.Sales) ? "" : User ? ", Sales" : "Sales"
        var Account = !this.isUserInRole(value, UserGroup.Account) ? "" : User || Sales ? ", Account" : "Account"
        var Legal = !this.isUserInRole(value, UserGroup.Legal) ? "" : User || Sales || Account ? ", Legal" : "Legal"
        var SEO = !this.isUserInRole(value, UserGroup.SEO) ? "" : User || Sales || Account || Legal ? ", SEO" : "SEO"
        var Writer = !this.isUserInRole(value, UserGroup.Writers) ? "" : User || Sales || Account || Legal || SEO ? ", Writer" : "Writer"
        var Admin = !this.isUserInRole(value, UserGroup.Admin) ? "" : User || Sales || Account || Legal || SEO ? ", Admin" : "Admin"
        return User + Sales + Account + Legal + SEO + Writer + Admin
    }

    public isUserInRole(currUserGroup: number, role: UserGroup): boolean {
        if (role === UserGroup.Any)
            return true;
        const res = (currUserGroup & role) === role;
        return res;
    }
}

export function isNullOrUndefined(obj: any): boolean {
    return obj === null || obj === undefined;
}

export function dateToStr(date: Date): string {
    return date.toISOString();
}

export function daysBetween(date1: Date, date2: Date) {
    //Get 1 day in milliseconds
    const one_day = 1000 * 60 * 60 * 24;

    // Convert both dates to milliseconds
    const date1_ms = date1.valueOf();
    const date2_ms = date2.valueOf();

    // Calculate the difference in milliseconds
    const difference_ms = date2_ms - date1_ms;

    // Convert back to days and return
    return Math.round(difference_ms / one_day);
}

export function stringToDate(str: any): Date {
    return !isNullOrUndefined(str) ? new Date(str) : null;
}

export function truncateWithEllipsis(text: string, maxLength: number): string {
    if (text.length > maxLength) {
        return text.slice(0, maxLength) + '...';
    }
    return text;
}