import { Component, inject, Input, OnInit } from '@angular/core';
import { Enums, InstanceStatus, InstanceStatusOnlyFE } from '../../../instruments/models';
import { softLegalStatusInfo } from '../../../instruments/models/content/contentWidget';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, NgFor, NgClass } from '@angular/common';
import { ValueOfEnumPipe } from 'src/app/instruments/helpers';
import { SoftLegalService } from 'src/app/instruments/services';

@Component({
    selector: 'widget-share-status',
    template: `
    <div *ngIf="data">
        <div class="row align-items-center" [ngClass]="{'mt-4': isFirst, 'mb-4': isLast}" *ngFor="let d of data.othersSoftLegalCustomers; first as isFirst; last as isLast;">
            <div class="col-12">
                <p>
                    <span *ngIf="data.lastStatusUpdateExtCustomer && d.id == data.lastStatusUpdateExtCustomer.id">Ultima modifica di: </span>
                    <a [href]="'/project;id=' + d.id" target="_blank"><strong>{{d.name}} {{d.surname}} </strong></a>
                    <span>con status: "<strong>{{(d.status | valueOfEnum: enums.SoftLegalLinkStatus:true).name | translate}}</strong>"</span>
                </p>
                <p *ngIf="d.instance">
                    <span>Istanza: {{d.instance.description}} - </span>
                    <span>con status: "<strong>{{(d.instance.status | valueOfEnum: enums.InstanceStatusOnlyFE:true).name | translate}}</strong>"</span>
                </p>
                <hr />
            </div>
        </div>
    </div>
`,
    standalone: true,
    imports: [NgIf, NgFor, NgClass, TranslateModule, ValueOfEnumPipe]
})
export class WidgetShareStatusComponent implements OnInit {
    @Input() data: softLegalStatusInfo
    enums: Enums = new Enums();
    softLegalService = inject(SoftLegalService);

    constructor() { }

    ngOnInit(): void {
        this.data.othersSoftLegalCustomers.forEach(o => {
            if(!o.instance)
                return;

            if ((o.instance.status != InstanceStatus.Closed && o.instance.status != InstanceStatusOnlyFE.AnomalyDetected)) {
                let allLinkStatus = this.softLegalService.SoftLegalCheckInstanceIsWaitingToClose(o.instance.linkSLStatus);
                o.instance.status = allLinkStatus ? InstanceStatusOnlyFE.WaitingForClose : o.instance.status;
            }
           
        })
        
    }
}
