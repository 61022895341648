import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AlertService } from '../../components/alert/alert.service';
import { ContentModel } from '../../instruments/models';
import { softLegalStatusInfo } from '../../instruments/models/content/contentWidget';
import { ContentService, NotificationService, SharedService } from '../../instruments/services';
import { SoftLegalService } from '../../instruments/services/soft-legal.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatBadgeModule } from '@angular/material/badge';
import { NgClass, NgIf, NgStyle, DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import {WidgetContentUpdateParams} from './widget-components/widget-set-channel/widget-set-channel.component';
import { EMPTY, switchMap } from 'rxjs';
import { truncateWithEllipsis } from '../../instruments/services/shared.service'

@Component({
    selector: 'widget-component',
    styleUrls: ['./widget-table.component.css'],
    templateUrl: './widget-table.component.html',
    standalone: true,
    imports: [MatTooltipModule, NgClass, NgIf, MatBadgeModule, NgStyle, DatePipe, TranslateModule]
})
export class WidgetTableComponent {
    @Input() row: ContentModel;

    constructor(public changeDetector: ChangeDetectorRef,
                private alert: AlertService,
                private snackbar: NotificationService,
                public shared: SharedService,
                public softLegalService: SoftLegalService,
                private contentService: ContentService) { }

    public emitDatasourceId(row: ContentModel, e: MouseEvent) {
        e.stopPropagation();
        let serpLastExecutions = [];

        if (row.contentWidget.serpLastExecutions.length > 0) {
            row.contentWidget.serpLastExecutions.map(entry => {
                if (entry.position) {
                    serpLastExecutions.push(entry);
                }
            })
            if (serpLastExecutions.length > 0) {
                this.alert.serpTable(new MatTableDataSource(serpLastExecutions), row.url);
            } else {
                this.snackbar.error('TITLES.SERP_ERROR');
            }
        }
    }

    public emitSoftLegal(row: ContentModel, e: MouseEvent) {
        e.stopPropagation();
        const statusInfo: softLegalStatusInfo = {
            othersSoftLegalCustomers: row.contentWidget.othersSoftLegalCustomers,
            lastStatusUpdateExtCustomer: row.contentWidget.lastStatusUpdateExtCustomer
        }

        if (statusInfo.othersSoftLegalCustomers && statusInfo.othersSoftLegalCustomers.length > 0) {
            this.alert.softLegalShareStatus(statusInfo, 'Condivisione link Soft Legal', truncateWithEllipsis(row.url, 35))
        } else {
            this.snackbar.error('TITLES.SOFTLEGAL_ERROR');
        }

    }

    public getColorEvaluation(entry) {
        if (entry.contentCustomerExtData.evaluation == 2) {
            return ' #73CF23'; //neutro
        }
        if (entry.contentCustomerExtData.evaluation == 1) {
            return '#15B337';//positive
        }
        if (entry.contentCustomerExtData.evaluation == 3) {
            return '#FF9D00';//lowneg
        }
        if (entry.contentCustomerExtData.evaluation == 0) {
            return '#BF1A2F ';  //neg
        }
        if (entry.contentCustomerExtData.evaluation == -1 || entry.contentCustomerExtData.evaluation == -2 || entry.contentCustomerExtData.evaluation == null) {
            return '#969696'; //null
        }
    }

    public emitPositioned(row: ContentModel, e: MouseEvent) {
        e.stopPropagation();
        this.alert.prompt("Sei sicuro di voler indicare come Posizionato il contenuto selezionato?").subscribe(res => {
            if (res == 'ok') {
                const newValue = !row.contentCustomerExtData.positioned
                row.contentCustomerExtData.positioned = newValue;
                this.contentService.updateContent(row).then(resp => {
                    if (resp.ok) {
                        row.contentWidget.positioned = newValue;
                    }
                    else {
                        this.snackbar.error();
                    }
                });
            }
        })
    }

    public emitChannel(row: ContentModel, e: MouseEvent){
        e.stopPropagation()

        const params = new WidgetContentUpdateParams(
            row.contactId,
            row.contentId,
            row.contentCustomerExtData.controlled,
            row.contentCustomerExtData.aggregator
        )


        this.alert.channelsSetStatus(params, 'Modifica piano canali').pipe(
            switchMap(val => {
                if(val === 'ok'){
                    return this.contentService.widgetContentUpdate(params);
                }
                else{
                    return EMPTY;
                }
            })
        ).subscribe({
            next: (resp) => {
                if(resp && resp.ok){
                    this.snackbar.done();
                    row.contentCustomerExtData.controlled = params.controlled;
                    row.contentCustomerExtData.aggregator = params.aggregator;
                    this.changeDetector.markForCheck();
                }
                else if(resp){
                    this.snackbar.error(resp.errorMessage);
                }
            },
            error: (err) => {
                this.snackbar.error(err.toString());
            }
        })


    }
}
